// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-router-dom';

function App() {
	return (
		<div className="wrapper">
			<img
				src="/images/background.png"
				alt="background"
				className="blushed-bg"
			/>
			{/* <img src="/images/footer.png" alt="footer" className="blushed-footer" /> */}
			<div className="policy-wrapper">
				{/* <div className="line" /> */}
				<div className="policy-item">
					All Rights Reserved © 2024 . <br />
					HOLY WATER LIMITED <br />
					Lykavitou, 46, Egkomi, 2401, Nicosia, Cyprus
				</div>
				<Link to="/terms-of-use" style={{ textDecoration: 'none' }}>
					<div className="policy-item">Terms</div>
				</Link>
				<Link to="/privacy-policy" style={{ textDecoration: 'none' }}>
					<div className="policy-item">Privacy</div>
				</Link>
				<Link to="/subscription-terms" style={{ textDecoration: 'none' }}>
					<div className="policy-item">Subscription terms</div>
				</Link>
			</div>
		</div>
	);
}

export default App;
